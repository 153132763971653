import { compactObject, serialize } from './helpers'

/**
 * Retrieve state with JSON parsing
 * @param {string} key - Key in localStorage
 * @returns {object|null} - Parsed JSON object
 */
export function getState(key) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }

  const data = localStorage.getItem(key)
  if (!data) return null
  return JSON.parse(data)
}

/**
 * Save state with JSON serialization
 * @param {string} key - Key in localStorage
 * @param {object} data - Data to save (JSON object)
 * @returns {object|null} - Saved data
 */
export function setState(key, data) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  data = compactObject(data)
  if (!data || !Object.keys(data).length) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, serialize(data))
  }
  return data
}

/**
 * Retrieve state without JSON parsing
 * @param {string} key - Key in localStorage
 * @returns {string|null} - Raw string value
 */
export function getRawState(key) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  return localStorage.getItem(key) || null
}

/**
 * Save state without JSON serialization
 * @param {string} key - Key in localStorage
 * @param {string} value - Raw string value to save
 */
export function setRawState(key, value) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  if (typeof value !== 'string' || !value.trim()) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
}

export function removeItemFromLocalStorage(key) {
  if (localStorage !== undefined) {
    localStorage.removeItem(key)
  }
}

// Attempts to remove an item from localStorage and logs failure if any
export function safelyRemoveItemFromLocalStorage(key) {
  try {
    removeItemFromLocalStorage(key)
  } catch (error) {
    logError('Failed to remove item from localStorage', { key, error })
  }
}

// Retrieves an object containing all local storage items
export function getAllLocalStorageItems() {
  try {
    const localStorageItems = {}
    const storage = localStorage

    if (storage) {
      for (let i = 0; i < storage.length; i++) {
        const key = storage.key(i)
        if (key) {
          localStorageItems[key] = getLocalStorageItem(key)
        }
      }
    }

    return localStorageItems
  } catch {
    return {}
  }
}

/**
 * Helper function to set a cookie for 30 days or expire it.
 * @param {string} name - The cookie name.
 * @param {string} value - The cookie value.
 * @param {number} [days=30] - Number of days until expiration. Use negative value to expire immediately.
 */
export const setCookie = (name, value, days = 30) => {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()
  document.cookie = `${name}=${value}; path=/; expires=${expires}; Secure; SameSite=Lax`
}

/**
 * Helper function to get all cookies as an object or retrieve a specific cookie by name.
 * @param {string} [name] - The cookie name to retrieve. If omitted, returns all cookies as an object.
 * @returns {string|Object|null} - The cookie value if a name is provided, an object of all cookies if omitted, or null if the cookie does not exist.
 */
export const getCookie = name => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=')
    if (key && value) {
      acc[key] = decodeURIComponent(value)
    }
    return acc
  }, {})

  return cookies[name] || null
}
